import React from 'react'
import { Link } from 'gatsby'
// import { GeoContext } from '../../state/geo'

// import LogoMerck from '../../assets/images/logo_m.inline.svg'
// import LogoEM from '../../assets/images/logo_em.inline.svg'
// import LogoDH from '../../assets/images/logo_dh.inline.svg'

import './logo.sass'

const BrandingLogo = () => {
  // const geo = useContext(GeoContext)
  // if (!geo || geo.loading) return null

  return (
    <Link to="/">
      <div className="logo">UX Platform</div>
    </Link>
  )
}

export default BrandingLogo
