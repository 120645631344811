import React from 'react';
import Container from '../container/container';
import Logo from '../logo/logo';

import './header.sass'

const Header = () => (
    <Container className="header">
        <Logo />
    </Container>
);

export default Header