import React from 'react'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import './layout.sass'
import '../styles/index.sass'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`

interface LayoutProps {
  children: React.ReactChildren
  withHeader?: boolean
}

const Layout = ({ children, withHeader = true }: LayoutProps) => {
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  const meta = {
    title: defaultTitle,
    description: defaultDescription,
    image: `${siteUrl}${defaultImage}`,
    url: `${siteUrl}`,
  }

  return (
    <>
      <Helmet
        title="UX Platform"
        meta={[
          {
            name: 'description',
            content:
              'Get access to the User Experience Strategy & Design unit of the Digital Organization, part of Merck KGaA, Darmstadt, Germany. Experience the Liquid Design system or see the UX toolkit!',
          },
          {
            name: 'keywords',
            content:
              'uxsd, ux, user experience, development, design, digital product, liquid design system, merck kgaa darmstadt Germany, liquid design system, ux method collection, ux toolkit',
          },
          {
            name: 'image',
            content: meta.image,
          },
          {
            name: 'og:url',
            content: meta.url,
          },
          {
            name: 'og:title',
            content: 'UX Platform',
          },
          {
            name: 'og:image',
            content: meta.image,
          },
          {
            name: 'og:description',
            content: meta.description,
          },
        ]}
      />
      <div className="layout">
        {withHeader ? <Header /> : null}
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
