import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { GeoContext } from '../../state/geo'
import Container from '../container/container'

import LogoMerck from '../../assets/images/logo_m_light.inline.svg'
import LogoEM from '../../assets/images/logo_em_light.inline.svg'

import DribbbleIcon from '../../assets/images/social_dribbble.inline.svg'
import BehanceIcon from '../../assets/images/social_behance.inline.svg'
import MediumIcon from '../../assets/images/social_medium.inline.svg'
import InstagramIcon from '../../assets/images/social_instagram.inline.svg'

import awardGermanBrand from '../../assets/images/award_card_gba.png'
import awardGermanInnovation from '../../assets/images/award_card_gia.png'
import awardDeutscherDigital from '../../assets/images/award_card_dda.png'

import './footer.sass'

const Footer = () => {
  const geo = useContext(GeoContext)

  if (!geo || geo.loading) return null

  return (
    <footer className="footer">
      <Container>
        <div className="footer__row">
          <div className="footer__main">
            <div className="footer__logo">
              {geo.brand === 'Merck' ? <LogoMerck /> : <LogoEM />}
            </div>
            {/* <div className="footer__awards">
              <a
                href="https://www.german-brand-award.com/preistraeger/galerie/detail/37307-liquid-design-system.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__awards-image"
              >
                <img alt="German Brand Award" src={awardGermanBrand} />
              </a>
              <a
                href="https://www.german-brand-award.com/preistraeger/galerie/detail/37307-liquid-design-system.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__awards-image"
              >
                <img
                  alt="German Innovation Award"
                  src={awardGermanInnovation}
                />
              </a>
              <a
                href="https://www.german-brand-award.com/preistraeger/galerie/detail/37307-liquid-design-system.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__awards-image"
              >
                <img
                  alt="German Innovation Award"
                  src={awardGermanInnovation}
                />
              </a>
              <a
                href="https://www.german-brand-award.com/preistraeger/galerie/detail/37307-liquid-design-system.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__awards-image"
              >
                <img
                  alt="Deutscher Digital Award"
                  src={awardDeutscherDigital}
                />
              </a>
            </div> */}
            <div className="footer__social">
              <a
                href="https://dribbble.com/uxsd"
                target="_blank"
                rel="noreferrer"
              >
                <DribbbleIcon />
              </a>
              <a
                href="https://www.behance.net/uxsd"
                target="_blank"
                rel="noreferrer"
              >
                <BehanceIcon />
              </a>
              <a
                href="https://medium.com/@uxsd"
                target="_blank"
                rel="noreferrer"
              >
                <MediumIcon />
              </a>
              <a
                href="https://www.instagram.com/uxstrategydesign/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            </div>
          </div>
          <div className="footer__nav nav nav--mobile-full">
            <div className="nav__title">Legal</div>
            <ul className="nav__menu">
              <li className="nav__item">
                <Link to="/privacy">Privacy statement</Link>
              </li>
              <li className="nav__item">
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li className="nav__item">
                <Link to="/imprint">Imprint</Link>
              </li>
            </ul>
          </div>
          <div className="footer__nav nav">
            <div className="nav__title">Team Up</div>
            <ul className="nav__menu">
              <li className="nav__item">
                <a href="https://www.uxsd.io" target="_blank" rel="noreferrer">
                  UX Strategy & Design
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="https://teams.microsoft.com/l/team/19%3aeae3b35b0cbf42659e45c2b5592e0c0e%40thread.tacv2/conversations?groupId=88f23881-53e2-4a99-ad5c-8188c1087bbf&tenantId=db76fb59-a377-4120-bc54-59dead7d39c9"
                  target="_blank"
                  rel="noreferrer"
                >
                  UX Teams Channel
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__nav nav">
            <div className="nav__title">Development</div>
            <ul className="nav__menu">
              <li className="nav__item">
                <a
                  href={`https://liquid.${geo.isUS ? 'emd' : 'merck'}.design`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Liquid Oxygen
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="https://github.com/emdgroup-liquid/liquid"
                  target="_blank"
                  rel="noreferrer"
                >
                  Liquid Oxygen Repository
                </a>
              </li>
              <li className="nav__item">
                <a
                  href={`https://www.figma.com/file/8GYcAOePm8Tt9qqJ7Gnv99/Liquid-Oxygen-(Share)?node-id=3%3A14310`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Liquid Oxygen Figma
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__bottom">
          <div>@ Merck KGaA, Darmstadt, Germany, 2021</div>
          <div>Made with ☕️️ by the UX Strategy & Design Team</div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
